var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"editor"},[_c('div',{attrs:{"id":_vm.toolbarId}},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8)]),_c('quillEditor',{ref:_vm.editorRef,attrs:{"options":_vm.option,"value":_vm.value},on:{"change":_vm.onEditorChange}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"ql-formats"},[_c('select',{staticClass:"ql-font"}),_c('select',{staticClass:"ql-size"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"ql-formats"},[_c('button',{staticClass:"ql-bold"}),_c('button',{staticClass:"ql-italic"}),_c('button',{staticClass:"ql-underline"}),_c('button',{staticClass:"ql-strike"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"ql-formats"},[_c('select',{staticClass:"ql-color"}),_c('select',{staticClass:"ql-background"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"ql-formats"},[_c('button',{staticClass:"ql-script",attrs:{"value":"sub"}}),_c('button',{staticClass:"ql-script",attrs:{"value":"super"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"ql-formats"},[_c('button',{staticClass:"ql-header",attrs:{"value":"1"}}),_c('button',{staticClass:"ql-header",attrs:{"value":"2"}}),_c('button',{staticClass:"ql-blockquote"}),_c('button',{staticClass:"ql-code-block"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"ql-formats"},[_c('button',{staticClass:"ql-list",attrs:{"value":"ordered"}}),_c('button',{staticClass:"ql-list",attrs:{"value":"bullet"}}),_c('button',{staticClass:"ql-indent",attrs:{"value":"-1"}}),_c('button',{staticClass:"ql-indent",attrs:{"value":"+1"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"ql-formats"},[_c('button',{staticClass:"ql-direction",attrs:{"value":"rtl"}}),_c('select',{staticClass:"ql-align"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"ql-formats"},[_c('button',{staticClass:"ql-link"}),_c('button',{staticClass:"ql-image"}),_c('button',{staticClass:"ql-video"}),_c('button',{staticClass:"ql-formula"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"ql-formats"},[_c('button',{staticClass:"ql-clean"})])
}]

export { render, staticRenderFns }
<template>
  <div class="editor">
    <div :id="toolbarId">
      <span class="ql-formats">
        <select class="ql-font"></select>
        <select class="ql-size"></select>
      </span>
      <span class="ql-formats">
        <button class="ql-bold"></button>
        <button class="ql-italic"></button>
        <button class="ql-underline"></button>
        <button class="ql-strike"></button>
      </span>
      <span class="ql-formats">
        <select class="ql-color"></select>
        <select class="ql-background"></select>
      </span>
      <span class="ql-formats">
        <button class="ql-script" value="sub"></button>
        <button class="ql-script" value="super"></button>
      </span>
      <span class="ql-formats">
        <button class="ql-header" value="1"></button>
        <button class="ql-header" value="2"></button>
        <button class="ql-blockquote"></button>
        <button class="ql-code-block"></button>
      </span>
      <span class="ql-formats">
        <button class="ql-list" value="ordered"></button>
        <button class="ql-list" value="bullet"></button>
        <button class="ql-indent" value="-1"></button>
        <button class="ql-indent" value="+1"></button>
      </span>
      <span class="ql-formats">
        <button class="ql-direction" value="rtl"></button>
        <select class="ql-align"></select>
      </span>
      <span class="ql-formats">
        <button class="ql-link"></button>
        <button class="ql-image"></button>
        <button class="ql-video"></button>
        <button class="ql-formula"></button>
      </span>
      <span class="ql-formats">
        <button class="ql-clean"></button>
      </span>
    </div>
    <quillEditor :ref="editorRef" :options="option" :value="value" @change="onEditorChange"></quillEditor>
  </div>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import { uploadFile } from '@/api'

export default {
    components: {
        quillEditor,
    },
    props: {
        value: {
            type: String,
            default: '',
        },
        editorRef: {
            type: String,
            default: 'editor',
        },
        toolbarId: {
            type: String,
            default: 'toolbar-container',
        },
    },
    data() {
        return {
            option: {
                modules: {
                    toolbar: {
                        container: `#${this.toolbarId}`,
                        handlers: {
                            image: this.currentImageHandler,
                            video: this.currentVideoHandler,
                        },
                    },
                },
                placeholder: 'Compose an epic...',
                theme: 'snow',
            },
        }
    },
    beforeMount() {
        console.log(this.option)
    },
    methods: {
        onEditorChange(e) {
            console.log(e)
            this.$emit('change', e)
        },
        currentImageHandler() {
            const inp = document.createElement('input')
            inp.setAttribute('type', 'file')
            inp.setAttribute('accept', 'image/*')
            inp.click()
            inp.onchange = async () => {
                const file = inp.files[0]
                let formData = new FormData()
                formData.append('file', file)
                const { success, data } = await uploadFile(formData)
                if (success) {
                    const range = this.$refs[this.editorRef].quill.getSelection(true)
                    this.$refs[this.editorRef].quill.insertEmbed(range.index, 'image', `https://${data.oss_url}`)
                }
                inp.remove()
            }
        },
        currentVideoHandler() {
            const inp = document.createElement('input')
            inp.setAttribute('type', 'file')
            inp.setAttribute('accept', 'video/*')
            inp.click()
            inp.onchange = async () => {
                const file = inp.files[0]
                let formData = new FormData()
                formData.append('file', file)
                const { success, data } = await uploadFile(formData)
                if (success) {
                    const range = this.$refs[this.editorRef].quill.getSelection(true)
                    this.$refs[this.editorRef].quill.insertEmbed(range.index, 'video', `https://${data.oss_url}`)
                }
                inp.remove()
            }
        },
    },
}
</script>

<style scoped>
:deep(.ql-editor) {
  height:300px;
}
</style>